<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchBsnDatePicker"
                    format="YYYY-MM"
                    type="lookup-condition"
                    depth="Year"
                    v-model="dailyReportSearchOptions.bsnDate"
                    :notClear="true"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="PRINT"
                @click.native="onPrintClicked"
                :is-shortcut-button="true"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<style scoped>
body .content-wrapper .content-body form {margin-top: -21px;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import {
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from '@/utils/date';
import InputDate from '@/components/common/datetime/InputDate';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import ReportView from '@/components/common/report/ReportView';
import {mapGetters} from 'vuex';
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import moment from 'moment';
import {DATE_FORMAT_YYYY_MM} from "../../utils/date";

export default {
  name: 'GolfMonthlyReservationStatus',
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputDate,
    ReportView,
    ErpButton,
  },
  async created() {
    this.dailyReportSearchOptions.bsnDate = moment().format(DATE_FORMAT_YYYY_MM);
    await this.onViewButtonClicked();
  },
  data() {
    return {
      dailyReportSearchOptions: {
        bsnDate: null,
        bsnCode: false,
      },
      reportStylesOptions: {text: 'comName', value: 'comCode'},
    };
  },
  computed: {
    ...mapGetters(['username']),
  },
  methods: {
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,

    async onViewButtonClicked() {
      if (!this.dailyReportSearchOptions.bsnDate) {
        return this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['영업월']),
        );
      }
      const golfMonthlyReservationStatusReportData = await GolfErpAPI.fetchGolfMonthlyReservationStatus(this.dailyReportSearchOptions.bsnDate);

      await this.postReport(golfMonthlyReservationStatusReportData);
    },
    onPrintClicked() {
      this.$refs.reportViewComponent.printReport();
    },
    async postReport(data) {
      const searchDate = moment(this.dailyReportSearchOptions.bsnDate).format('YYYY년 MM월');

      const reportData = {
        reportJson: {
          jsonData: data,
          username: this.username,
          uniqueCode: this.$options.name,
          searchDate,
        },
      };

      this.$refs.reportViewComponent.postReport(
          reportData,
          `${this.$options.name}`,
      );
    },
  },
};
</script>
